
export const getProducts = async (urlParams = {}) => {
    const url = new URL(process.env.NEXT_PUBLIC_API_URL + '/crux/v1/products');
    let filterParams = Object.fromEntries(
        Object.entries(urlParams).filter(([key, value]) => value !== null && value !== undefined)
    )

    const params = {
        consumer_key: process.env.NEXT_PUBLIC_WC_CUSTOMER_KEY,
        consumer_secret: process.env.NEXT_PUBLIC_WC_CUSTOMER_SECRET
    };

    url.search = new URLSearchParams({
        ...params
    }).toString();

    const result = await fetch(url, { next: { revalidate: 10 } })
    return await result.json()
}

export const getWCProducts = async () => {
    const url = new URL(process.env.NEXT_PUBLIC_API_URL + '/wc/v3/products');
    const params = {
        consumer_key: process.env.NEXT_PUBLIC_WC_CUSTOMER_KEY,
        consumer_secret: process.env.NEXT_PUBLIC_WC_CUSTOMER_SECRET
    };

    url.search = new URLSearchParams({
        ...params
    }).toString();

    const result = await fetch(url, { next: { revalidate: 10 }})
    const resultJson = await result.json()
    return { data: resultJson }
}

export const getWCSpecialProducts = async (urlParams = {}) => {
    const url = new URL(process.env.NEXT_PUBLIC_API_URL + '/wc/v3/products');
    const params = {
        consumer_key: process.env.NEXT_PUBLIC_WC_CUSTOMER_KEY,
        consumer_secret: process.env.NEXT_PUBLIC_WC_CUSTOMER_SECRET
    };
    url.search = new URLSearchParams({
        ...urlParams,
        ...params
    }).toString();

    const result = await fetch(url, { next: { revalidate: 10 } })
    const resultJson = await result.json()
    return { data: resultJson }
}

export const getProduct = async (id) => {
    const url = new URL(process.env.NEXT_PUBLIC_API_URL + '/wc/v3/products/' + id);
    const params = {
        consumer_key: process.env.NEXT_PUBLIC_WC_CUSTOMER_KEY,
        consumer_secret: process.env.NEXT_PUBLIC_WC_CUSTOMER_SECRET
    };
    url.search = new URLSearchParams(params).toString();

    const result =  await fetch(url, {next: {revalidate: 10}})

    return await result.json()
}

export const getProductBySlug = async (slug) => {
    const url = new URL(process.env.NEXT_PUBLIC_API_URL + '/wc/v3/products');
    const params = {
        slug: slug,
        consumer_key: process.env.NEXT_PUBLIC_WC_CUSTOMER_KEY,
        consumer_secret: process.env.NEXT_PUBLIC_WC_CUSTOMER_SECRET
    };
    url.search = new URLSearchParams(params).toString();

    const result = await fetch(url, {next: {revalidate: 10}})

    return await result.json()
}

export const createOrder = async (orderData) => {
    const url = new URL(`${process.env.NEXT_PUBLIC_API_URL}/wc/v3/orders`);
    const params = {
        consumer_key: process.env.NEXT_PUBLIC_WC_CUSTOMER_KEY,
        consumer_secret: process.env.NEXT_PUBLIC_WC_CUSTOMER_SECRET,
    };
    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json();
};

export const updateOrder = async (id, orderData) => {
    const url = new URL(`${process.env.NEXT_PUBLIC_API_URL}/wc/v3/orders/${id}`);
    const params = {
        consumer_key: process.env.NEXT_PUBLIC_WC_CUSTOMER_KEY,
        consumer_secret: process.env.NEXT_PUBLIC_WC_CUSTOMER_SECRET,
    };
    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json();
};

export const addToCoCart = async (orderData, cartKey) => {
    const url = new URL(`${process.env.NEXT_PUBLIC_API_URL}/cocart/v2/cart/add-item`);
    const params = {
        consumer_key: process.env.NEXT_PUBLIC_WC_CUSTOMER_KEY,
        consumer_secret: process.env.NEXT_PUBLIC_WC_CUSTOMER_SECRET,
        cart_key: cartKey ? cartKey : '',
    };
    url.search = new URLSearchParams(params).toString();
    orderData.status = 'processing';


    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json();
}

export const clearCoCart = async () => {
    const url = new URL(`${process.env.NEXT_PUBLIC_API_URL}/cocart/v2/cart/clear`);
    const params = {
        consumer_key: process.env.NEXT_PUBLIC_WC_CUSTOMER_KEY,
        consumer_secret: process.env.NEXT_PUBLIC_WC_CUSTOMER_SECRET,
    };
    url.search = new URLSearchParams(params).toString();


    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            cart_key: '1',
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json();
}

export const getCoCart = async (cartKey) => {
    const url = new URL(process.env.NEXT_PUBLIC_API_URL + `/cocart/v2/cart?cart_key=${cartKey}`);

    const params = {
        consumer_key: process.env.NEXT_PUBLIC_WC_CUSTOMER_KEY || '',
        consumer_secret: process.env.NEXT_PUBLIC_WC_CUSTOMER_SECRET || '',
        per_page: 100,
    };

    url.search = new URLSearchParams(params).toString();

    try {
        const result = await fetch(url, { next: { revalidate: 0 } });
        const data = await result.json();

        return data;
    } catch (error) {
        console.error('Greška prilikom dohvatanja kupona:', error);
        throw error; // Propagate the error for handling in the calling code
    }
};

export const createOrderNote = async (id, data) => {
    const url = new URL(`${process.env.NEXT_PUBLIC_API_URL}/wc/v3/orders/${id}/notes`);
    const params = {
        consumer_key: process.env.NEXT_PUBLIC_WC_CUSTOMER_KEY,
        consumer_secret: process.env.NEXT_PUBLIC_WC_CUSTOMER_SECRET,
    };
    url.search = new URLSearchParams(params).toString();
    data.status = 'processing';


    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json();
};

export const getCoupons = async () => {
    const url = new URL(process.env.NEXT_PUBLIC_API_URL + `/wc/v3/coupons`);

    const params = {
        consumer_key: process.env.NEXT_PUBLIC_WC_CUSTOMER_KEY || '',
        consumer_secret: process.env.NEXT_PUBLIC_WC_CUSTOMER_SECRET || '',
        per_page: 100,
    };

    url.search = new URLSearchParams(params).toString();

    try {
        const result = await fetch(url, { next: { revalidate: 0 } });
        const data = await result.json();

        return data;
    } catch (error) {
        console.error('Greška prilikom dohvatanja kupona:', error);
        throw error; // Propagate the error for handling in the calling code
    }
};

export const getDiscount = async (id, data) => {
    const url = new URL(`${process.env.NEXT_PUBLIC_API_URL}/wc/v3/store`);
    const params = {
        consumer_key: process.env.NEXT_PUBLIC_WC_CUSTOMER_KEY,
        consumer_secret: process.env.NEXT_PUBLIC_WC_CUSTOMER_SECRET,
    };
    url.search = new URLSearchParams(params).toString();
    data.status = 'processing';


    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json();
};
